import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "EMC and EMI Compliance",
  "description": "A brief introduction to EMC and EMI Compliance:, ",
  "author": "Jacen Wang",
  "categories": ["certification"],
  "date": "2022-05-24T17:00:00.000Z",
  "featured": false,
  "tags": [],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`For most countries, it is mandatory to conduct EMC testing and certification on electronic products before sales in the countries. Thus, relevant parties in charge of product design, sales, and manufacture must have a full picture of test items as well as key indicators and parameters. By doing so, the completion of the certification could be done with the least attempt, or with the least design changes. This means the findings from the testing can be addressed faster, thus accelerating the product's debut in the market.`}</p>
    <p>{`A number of concepts and correlations must be understood prior to EMC certification as this logical relationship plays a decisive role in defining the quantity and correlation of test items. Consequently, adequate corrective measures can be made to deal with test items that fail.`}</p>
    <p><strong parentName="p">{`EMI`}</strong>{`: the abbreviation of `}<strong parentName="p">{`Electromagnetic Interference`}</strong>{`, meaning that the electromagnetic wave from electronic devices in service may lead to radiated interference with components inside and outside the devices.`}</p>
    <p><strong parentName="p">{`EMS`}</strong>{`: the abbreviation of `}<strong parentName="p">{`Electromagnetic Susceptibility`}</strong>{`, meaning that electronic devices in service are susceptible to EMI in the environment.`}</p>
    <p><strong parentName="p">{`EMC`}</strong>{`: the abbreviation of `}<strong parentName="p">{`Electromagnetic Compatibility`}</strong>{`, meaning that electronic devices shall operate in a way not yielding severe interference, or presenting excellent interference resistance. It is typically defined as follows: “the device and system can operate well under its electromagnetic environment to an extent without leading to any unacceptable electromagnetic disturbance to any parts or components nearby. “`}</p>
    <p>{`The above definition suggests that EMC is `}<em parentName="p">{`inclusive`}</em>{` of EMI and EMS, which are logically correlated below:`}</p>
    <h2 {...{
      "id": "emi-test-items"
    }}>{`EMI test items`}</h2>
    <p>{`Typically, there is an applicable standard governing and defining the items to be tested. However, since there are multiple revisions, the terms are different in different countries and regions, for different types of devices. Therefore, the descriptions stated below are only qualitative descriptions without covering the exact standard terms and related parameters. `}</p>
    <h3 {...{
      "id": "re-radiated-emission"
    }}>{`RE radiated emission`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the electromagnetic intensity of the radiation generated by the product to its surrounding.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: Conducted in a shielding dark room that is equipped with a test bench for the product requiring tests, also with a receiving antenna appropriately spaced from the test bench. The device is operated at different modes to detect the electromagnetic intensity received at the antenna. The standard may detail the mounting requirements on the antenna height, direction of polarization, rotation angle, radiation direction, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: When defining the frequency band and distance, you should set the limits for different frequency ranges and distances. These standard values might be different for different types of devices, as the values of RE radiated emission for household devices and electrical control devices are generally different.`}</li>
    </ul>
    <h3 {...{
      "id": "ce-conducted-emission"
    }}>{`CE conducted emission`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the interference generated by the product towards the external power supply.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: A power supply is used to test the product by connecting the power cable for voltage/current interference, peak, etc. A variety of specific test settings are available, such as current probes and power impedance simulation. Different standards and test laboratories may differ from each other.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: Only low frequency is covered, typically less than 30 MHz, within which the peak or mean limit at different frequencies may be defined. Application scenarios are defined by device type.`}</li>
    </ul>
    <h3 {...{
      "id": "harmonic-current"
    }}>{`Harmonic current`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the harmonic current flow into the municipal power grid from the product.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: A clean AC power supply and a harmonic analyzer are used to test the peak at individual harmonics.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: Different limits are imposed for different types of products.`}</li>
    </ul>
    <h3 {...{
      "id": "flash"
    }}>{`Flash`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the product’s voltage fluctuation and figure out its impact on the municipal power grid. Such fluctuation may upset other devices built on the grid, such as light flash.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: Special instruments are generally used for testing. Different test methods are available, such as full-wave rectification and half-wave rectification.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: The test is done until the voltage fluctuation limits (maximum, mean, and duration) are recorded.`}</li>
    </ul>
    <h2 {...{
      "id": "ems-test-items"
    }}>{`EMS test items`}</h2>
    <h3 {...{
      "id": "radiated-susceptibility"
    }}>{`Radiated susceptibility`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the product’s resistance to electromagnetic wave interference in space.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: A shielding dark room that is equipped with a test bench for the product requiring tests and an antenna appropriately spaced from the test bench. The antenna-based electromagnetic emission is made from the modulating or non-modulating signal source to detect the electromagnetic intensity received at the antenna.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: Testing is done until a number of frequency band zones from tens of MHz to several GHz until it reaches a limit. `}</li>
    </ul>
    <h3 {...{
      "id": "conducted-immunity"
    }}>{`Conducted immunity`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the product’s resistance to external interference.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: A signal generator is used to produce the LF interference voltage signal. The signal is introduced via a multiplexer, and the signal intensity is recorded at the other end.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: The voltage amplitude is varied for both modulating signals and non-modulating signals.`}</li>
    </ul>
    <h3 {...{
      "id": "electrostatic-discharge-esd-immunity"
    }}>{`Electrostatic discharge (ESD) immunity`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the product’s resistance level under electrostatic discharge.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: A shielding bench and an ESD gun. The testing temperature and humidity are also controlled during the test.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: The test records for contact discharge and air discharge. The standard limits differ for different product types.`}</li>
    </ul>
    <h3 {...{
      "id": "surge-immunity"
    }}>{`Surge immunity`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the product’s resistance to the power surge under switching of the power system, power exposure to the lightning stroke, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: A surge generator is used to generate the interference source coupled via power cables.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: Typically, the product is expected to work well under the varying waveform and time.`}</li>
    </ul>
    <h3 {...{
      "id": "electrical-fast-transient-eft"
    }}>{`Electrical fast transient (EFT)`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the product’s resistance to such coupled pulses from the power supply, control, or signal cables under conditions where transient actions such as inductive load interruptions and relay On/Off cause numerous HF pulses at the power supply.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: Uses 2 main devices, a pulse signal generator, and a coupling device.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: To define the level of output consistency achieved even when exposed to the varying pulse waveform, repetition frequency, and peak.`}</li>
    </ul>
    <h3 {...{
      "id": "voltage-dipshort-interruption"
    }}>{`Voltage dip/short interruption`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: To detect the product’s resistance to power voltage dip or short interruption.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: Uses a voltage interference signal generator and an oscilloscope to generate different voltage waveforms through current coupling.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: Meant to make classification by the level and duration.`}</li>
    </ul>
    <h3 {...{
      "id": "power-frequency-magnetic-field-immunity"
    }}>{`Power frequency magnetic field immunity`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Aim`}</strong>{`: Meant to detect the resistance of the product sensitive to the magnetic field under the induced magnetic field with low frequency and long wavelength.`}</li>
      <li parentName="ul"><strong parentName="li">{`Method`}</strong>{`: Uses either an excited AC power supply or an induction coil. They will be used to test the product’s exposure to magnetic field interference in different directions, which are done by adjusting the coil location.`}</li>
      <li parentName="ul"><strong parentName="li">{`Testing conditions`}</strong>{`: Meant to define the magnetic field intensity grade, continuity, and duration.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      